import React from "react";

const BrandLogo = () => {
  return (
    <svg
      width="101"
      height="94"
      viewBox="0 0 101 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50.1763 45.7046C51.1666 47.6259 51.7433 50.181 51.9496 52.9405C54.5689 50.7329 60.9611 49.5269 66.0136 51.5301C60.6928 51.5301 54.0943 54.3713 51.9496 57.3352C51.5581 63.1606 49.5996 68.9248 46.2793 71.0915C60.7138 73.3399 66.5293 54.2895 78.6749 55.7816C73.8497 44.0081 56.3833 41.4938 50.1763 45.7046Z"
        fill="#6CA448"
      />
      <path
        d="M38.8355 16.618C21.8026 18.7233 7.69856 49.7313 20.2977 91.0413C21.2459 94.1891 23.659 93.8416 27.9686 93.3919C52.0124 90.8777 98.0585 85.9721 100.863 54.8414C102.636 35.1983 86.9432 21.1353 67.6835 19.8885C67.2299 19.868 66.8595 19.541 66.6532 19.1526C63.7044 13.5315 53.0848 0 38.5061 0C-4.19972 0 -5.41628 70.5397 6.46095 89.4878C9.22451 93.8825 13.2657 93.392 12.2343 90.4894C1.49158 60.4217 10.7915 9.34124 39.5774 9.34124C48.7953 9.34124 55.1875 15.0236 61.4155 26.7769C61.6628 27.2674 62.1985 27.4514 62.7352 27.5127C83.624 30.2517 91.9336 43.7424 90.635 54.9641C89.1911 67.5554 79.726 81.0664 27.1845 86.8715C24.6483 81.8636 18.8338 50.2424 28.1948 35.2801C23.8853 53.5128 28.1948 69.8243 31.7424 75.5883C42.6293 71.2346 46.6716 66.3085 46.6716 55.3525C46.6716 39.2045 31.7003 29.9655 38.8355 16.618Z"
        fill="#6CA448"
      />
    </svg>
  );
};

export default BrandLogo;
